import { curve, marker } from 'leaflet';
import { useMap } from "react-leaflet";
import 'leaflet-rotatedmarker';
import '@elfalem/leaflet-curve';

export default function Bezier({step, startCoords, endCoords, distortion, polarity, detail}) {

    const map = useMap();

    const offsetX = endCoords[1] - startCoords[1];
    const offsetY = endCoords[0] - startCoords[0];

    const r = Math.sqrt(Math.pow(offsetX, 2) + Math.pow(offsetY, 2));
    const theta = Math.atan2(offsetY, offsetX)+distortion; 

    const r2 = (r / 2) / (Math.cos(.314));
    const theta2 = polarity == 0 ? theta - .314 : theta + .314;

    const midpointX = (r2 * Math.sin(theta2)) + startCoords[0];
    const midpointY = (r2 * Math.cos(theta2)) + startCoords[1];

    const midCurveX = .5 * .5 * startCoords[0] + .5 * midpointX + .5 * .5 * endCoords[0];
    const midCurveY = .5 * .5 * startCoords[1] + .5 * midpointY + .5 * .5 * endCoords[1];
    const midCurve = [midCurveX, midCurveY];

    const midpointLatLng = [midpointX, midpointY];

    let angle = 0;
    if (step == "step2") {
        angle = -45;
    } else if (step == "step6") {
        angle = 45;
    }

    curve(['M', startCoords, 'Q', midpointLatLng, endCoords], {color: 'black', weight: 3}).addTo(map);
    if (step == "step10") {
        return;
    } else if (step != "step12") {
        marker(endCoords, {rotationAngle: angle}).addTo(map).bindTooltip(detail);
    } else {
        marker(endCoords).addTo(map).bindTooltip("Toulouse - Début & Fin du périple");
    }

}