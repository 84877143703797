import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

import Home from "./Home";
import Map from "./Map";
import NotFound from "./NotFound";

export default function App() {
    return (
        <Router>
            <Routes>
                <Route path="/" element={<Home/>}/>
                <Route path="/map" element={<Map/>}/>
                <Route path="*" element={<NotFound/>}/>
            </Routes>
        </Router>
    );
}
