import React from "react";
import Bezier from "./Bezier";
import 'leaflet-rotatedmarker';
import '@elfalem/leaflet-curve';
import { ReactComponent as CarSVG } from "./SVG/car.svg";
import { MapContainer, TileLayer, useMap, Marker, Popup, SVGOverlay, ImageOverlay } from "react-leaflet";

export default function Map() {

    const journey = {
        step1: { // Toulouse - Tarbes
            startCoords: [43.6043, 1.4437],
            endCoords: [43.21775600541858, 0.08492044998198627],
            distortion: 0,
            polarity: 1,
            startDate: new Date(2024, 2, 1), //1er mars
            endDate: new Date(2024, 2, 7), //7 mars
            detail: "<b>1. Tarbes</b><br/>Festival agricole<br/>7 - 10 Mars 2024",
            nextStep: "step2"
        },
        step2: { // Tarbes - Bayonne
            startCoords: [43.21775600541858, 0.08492044998198627],
            endCoords: [43.4904358741952, -1.4750571953473026],
            distortion: 1,
            polarity: 0,
            startDate: new Date(2024, 2, 10), //10 mars
            endDate: new Date(2024, 3, 4), //4 avril
            detail: "<b>2. Bayonne</b><br/>Foire au jambon<br/>4 - 7 Avril 2024",
            nextStep: "step3"
        },
        step3: { // Bayonne - Condom
            startCoords: [43.4904358741952, -1.4750571953473026],
            endCoords: [43.958194905286355, 0.3727911038342791],
            distortion: .8,
            polarity: 1,
            startDate: new Date(2024, 3, 7), //7 avril
            endDate: new Date(2024, 4, 10), //10 mai
            detail: "<b>3. Condom</b><br/>Festival de bandas<br/>10 - 12 Mai 2024",
            nextStep: "step4"
        },
        step4: { // Condom - Vic-Fezensac
            startCoords: [43.958194905286355, 0.3727911038342791],
            endCoords: [43.76101553958903, 0.30109199074640236],
            distortion: 0,
            polarity: 0,
            startDate: new Date(2024, 4, 12), //12 mai
            endDate: new Date(2024, 4, 17), //17 mai
            detail: "<b>4. Vic-Fezensac</b><br/>Pentecôtavic<br/>17 - 20 Mai 2024",
            nextStep: "step5"
        },
        step5: { // Vic-Fezensac - Pampelune
            startCoords: [43.76101553958903, 0.30109199074640236],
            endCoords: [42.817095104308585, -1.6431695930624384],
            distortion: 0,
            polarity: 1,
            startDate: new Date(2024, 4, 20), //20 mai
            endDate: new Date(2024, 6, 6), //6 juillet
            detail: "<b>5. Pampelune</b><br/>Fêtes de San Fermin<br/>6 - 14 Juillet 2024",
            nextStep: "step6"
        },
        step6: { // Pampelune - Bayonne
            startCoords: [42.817095104308585, -1.6431695930624384],
            endCoords: [43.4904358741952, -1.4750571953473026],
            distortion: 0,
            polarity: 1,
            startDate: new Date(2024, 6, 8), //8 juillet
            endDate: new Date(2024, 6, 10), //10 juillet
            detail: "<b>6. Bayonne</b><br/>Fêtes de Bayonne<br/>10 - 14 Juillet 2024",
            nextStep: "step7"
        },
        step7: { // Bayonne - Mont-de-Marsan
            startCoords: [43.4904358741952, -1.4750571953473026],
            endCoords: [43.891067688340655, -0.5008316913205518],
            distortion: 0,
            polarity: 0,
            startDate: new Date(2024, 6, 14), //14 juillet 
            endDate: new Date(2024, 6, 17), //17 juillet
            detail: "<b>7. Mont-de-Marsan</b><br/>Fêtes de la Madeleine<br/>17 - 21 Juillet 2024",
            nextStep: "step8"
        },
        step8: { // Mont-de-Marsan - Dax
            startCoords: [43.891067688340655, -0.5008316913205518],
            endCoords: [43.708775882188554, -1.0530533765083103],
            distortion: 0,
            polarity: 0,
            startDate: new Date(2024, 6, 21), //21 juillet
            endDate: new Date(2024, 7, 14), //14 août
            detail: "<b>8. Dax</b><br/>Dax la féria !<br/>14 - 18 Août 2024",
            nextStep: "step9"
        },
        step9: { // Dax - Seissan
            startCoords: [43.708775882188554, -1.0530533765083103],
            endCoords: [43.49244525545394, 0.5940766573034556],
            distortion: 0,
            polarity: 0,
            startDate: new Date(2024, 7, 18), //18 août
            endDate: new Date(2024, 7, 31), //31 août
            detail: "<b>9. Seissan</b><br/>Encierro<br/>31 Août - 2 Septembre 2024",
            nextStep: "step10"
        },
        step10: { // Seissan - Toulouse
            startCoords: [43.49244525545394, 0.5940766573034556],
            endCoords: [43.6043, 1.4437],
            distortion: 0,
            polarity: 1,
            startDate: new Date(2024, 8, 2), //2 septembre
            endDate: new Date(2024, 8, 3), //3 septembre
            detail: "Fin du périple",
            nextStep: "step11"
        },
        step11: { // Toulouse - Lloret de Mar
            startCoords: [43.6043, 1.4437],
            endCoords: [41.70017747161226, 2.8427863593638585],
            distortion: 0,
            polarity: 1,
            startDate: new Date(2024, 6, 5),
            endDate: new Date(2024, 6, 8),
            detail: "<b>?. Lloret de Mar</b><br/>Local poubelle<br/>?? - ?? ???? 2024",
            nextStep: "step12"
        },
        step12: { // Lloret de Mar - Toulouse
            startCoords: [41.70017747161226, 2.8427863593638585],
            endCoords: [43.6043, 1.4437],
            distortion: 0,
            polarity: 1,
            startDate: new Date(2024, 6, 12),
            endDate: new Date(2024, 6, 15),
            detail: "Lloret de Mar - Toulouse",
            nextStep: "step1"
        }
    }
    const [percentage, setPercentage] = React.useState(0);
    const [pathCurves, setPathCurves] = React.useState([]);
    const [carPosition, setCarPosition] = React.useState([0, 0]);
    const [currentStep, setCurrentStep] = React.useState(getCurrentStep());

    //on init les curves
    React.useEffect(() => {

        let pathCurves = []
        {Object.entries(journey).map((step) => {
            pathCurves.push(<Bezier key={step[0]} step={step[0]} startCoords={step[1].startCoords} endCoords={step[1].endCoords} distortion={step[1].distortion} polarity={step[1].polarity} detail={step[1].detail} />)
        })}
        setPathCurves(pathCurves);

    }, []);

    //on récupère l'étape actuelle
    function getCurrentStep() {
        const now = new Date();
        for (const step in journey) {
            if (now > journey[step].startDate.getTime() && now < journey[step].endDate.getTime()) {
                return step;
            } else if (now > journey[step].endDate.getTime() && now < journey[journey[step].nextStep].startDate.getTime()){
                return journey[step].nextStep;
            }
        }
    }

    function Car() {

        setTimeout(() => {

            const now = new Date();
            for (const step in journey) {
                if (now > journey[step].startDate.getTime() && now < journey[step].endDate.getTime()) {
                    setCurrentStep(step);
                    setPercentage((now - journey[step].startDate.getTime()) / (journey[step].endDate.getTime() - journey[step].startDate.getTime()));
                    break;
                } else if (now > journey[step].endDate.getTime() && now < journey[journey[step].nextStep].startDate.getTime()){
                    setCurrentStep(step);
                    setPercentage(1);
                    break;
                }
            }

            const offsetX = journey[currentStep].endCoords[1] - journey[currentStep].startCoords[1];
            const offsetY = journey[currentStep].endCoords[0] - journey[currentStep].startCoords[0];

            const r = Math.sqrt(Math.pow(offsetX, 2) + Math.pow(offsetY, 2));
            const theta = Math.atan2(offsetY, offsetX)+journey[currentStep].distortion;

            const r2 = (r / 2) / (Math.cos(.314));
            const theta2 = journey[currentStep].polarity == 0 ? theta - .314 : theta + .314;

            const midpointX = (r2 * Math.sin(theta2)) + journey[currentStep].startCoords[0];
            const midpointY = (r2 * Math.cos(theta2)) + journey[currentStep].startCoords[1];

            const x = (1 - percentage) * (1 - percentage) * journey[currentStep].startCoords[0] + 2 * (1 - percentage) * 
                percentage * midpointX + percentage * percentage * journey[currentStep].endCoords[0];
            const y = (1 - percentage) * (1 - percentage) * journey[currentStep].startCoords[1] + 2 * (1 - percentage) * 
                percentage * midpointY + percentage * percentage * journey[currentStep].endCoords[1];
            
            setCarPosition([x, y]);
    
        }, 1000);

        return (
            <SVGOverlay bounds={[[carPosition[0]-.1,carPosition[1]-.1], [carPosition[0]+.1,carPosition[1]+.1]]}>
                <CarSVG/>
            </SVGOverlay>
        );

    }

    return (
        <MapContainer center={[43.215725805856835, 1.4389008530145484]} zoom={8} style={{ height: "100vh" }}>
            <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"/>
            {pathCurves}
            <Car/>
        </MapContainer>
    );

}