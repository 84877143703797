import { Button, Typography, Box } from '@mui/material';

export default function Home() {
    return (
        <Box display="flex" justifyContent="center" alignItems="center" flexDirection="column" style={{ height: '100vh', padding: '20px' }}>
            <Typography variant="h1" sx={{ mb: 2 }}>Bienvenue sur le site du Grub 72</Typography>
            <Button variant="contained" color="primary" href="/map">Carte interactive des fêtes de l'été</Button>
        </Box>
    );
}
